import {
	GET_TEAMS,
	GET_TEAMS_SUCCESS,
	GET_TEAMS_ERROR,
	
	GET_TEAM_BY_ID,
	GET_TEAM_BY_ID_SUCCESS,
	GET_TEAM_BY_ID_ERROR,
	
	GET_ALL_TEAMS,GET_ALL_TEAMS_SUCCESS,GET_ALL_TEAMS_ERROR,


	GET_FILTERATION_DATA_TEAM,
	GET_FILTERATION_DATA_TEAM_SUCCESS,
	GET_FILTERATION_DATA_TEAM_ERROR,

	GET_TEAMS_BY_COMPANY,
	GET_TEAMS_BY_COMPANY_SUCCESS,
	GET_TEAMS_BY_COMPANY_ERROR,
	DELETE_TEAM,
	DELETE_TEAM_SUCCESS,
	DELETE_TEAM_ERROR,



	SAVE_TEAM,
	SAVE_TEAM_SUCCESS,
	SAVE_TEAM_ERROR,

	UPDATE_TEAM_DATA,
	UPDATE_TEAM_DATA_SUCCESS,
	UPDATE_TEAM_DATA_ERROR,

	CLEAR_TEAM_SUCCESS,

	RESET_FILTERATION_DATA_SUCCESS,
	RESET_FILTERATION_DATA,

} from "../actions";

export const getAllTeams = () => ({
	type: GET_ALL_TEAMS,
});

export const getAllTeamsSuccess = (teams) => ({
	type: GET_ALL_TEAMS_SUCCESS,
	payload: teams,
});

export const getAllTeamsError = (message) => ({
	type: GET_ALL_TEAMS_ERROR,
	payload: { message },
})


export const getTeamsByCompany = (id) => ({
	type: GET_TEAMS_BY_COMPANY,
	payload:{companyId:id}
});

export const getTeamsByCompanySuccess = (teams) => ({
	type: GET_TEAMS_BY_COMPANY_SUCCESS,
	payload: teams,
});

export const getTeamsByCompanyError = (message) => ({
	type: GET_TEAMS_BY_COMPANY_ERROR,
	payload: { message },
})

export const getTeamById = (depTeam) => ({
	type: GET_TEAM_BY_ID,
	payload:  depTeam,
});

export const getTeamByIdSuccess = (team) => ({
	type: GET_TEAM_BY_ID_SUCCESS,
	payload: team,
});

export const getTeamByIdError = (message) => ({
	type: GET_TEAM_BY_ID_ERROR,
	payload: { message },
});
export const getTeams = (id) => ({
	type: GET_TEAMS,
	payload: { id },
});

export const getTeamsSuccess = (teams) => ({
	type: GET_TEAMS_SUCCESS,
	payload: teams,
});

export const getTeamsError = (message) => ({
	type: GET_TEAMS_ERROR,
	payload: { message },
});

export const saveTeam = (team) => ({
	type: SAVE_TEAM,
	payload: { team },
});

export const saveTeamSuccess = (team) => ({
	type: SAVE_TEAM_SUCCESS,
	payload: team,
});


export const saveTeamError = (message) => ({
	type: SAVE_TEAM_ERROR,
	payload: { message },
});

 export const updateTeamData = (team,id) => ({
 type: UPDATE_TEAM_DATA,
	payload: { team,id },
 });

export const updateTeamDataSuccess = (message) => ({
	type: UPDATE_TEAM_DATA_SUCCESS,
	payload: {message},
});

export const updateTeamDataError = (message) => ({
	type: UPDATE_TEAM_DATA_ERROR,
	payload: { message },
});


export const deleteTeamById = (id) => ({
	type: DELETE_TEAM,
	payload: { id },
});

export const deleteTeamSuccess = (id) => {
    return {
        type: DELETE_TEAM_SUCCESS,
        payload: {id},
    };
};
export const deleteTeamError = (message) => ({
	type: DELETE_TEAM_ERROR,
	payload: { message },
});





export const getFilternationDataTeam = (filterName) => {
	return{
		type: GET_FILTERATION_DATA_TEAM,
		payload:  filterName ,
	}
	
};


export const getfilternationTeamsSuccess = (teams) => {

	
	return {
		type: GET_FILTERATION_DATA_TEAM_SUCCESS,
	payload:  teams ,
    };
};

export const getfilternationTeamsError = (message) => {
	

	return {
		type: GET_FILTERATION_DATA_TEAM_ERROR,
		payload:  {message} ,
    };
};


export const resetFiltersTeam= (team) => {
	return{
		type: RESET_FILTERATION_DATA,
		payload:  {team} ,
	}	
	
};


export const filterResetTeamSuccess= (team) => {
	return{
		type: RESET_FILTERATION_DATA_SUCCESS,
		payload:  team ,
	}	
	
};
export const clearTeamSuccess = () => ({
	type: CLEAR_TEAM_SUCCESS,
});