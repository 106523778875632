import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { appRoot } from "constants/defaultValues";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import {
  GET_CARD,
  GET_CARD_BY_USERNAME,
  SAVE_CARD,
  UPDATE_CARD,
  DELETE_CARD,
  UPDATE_CARD_WIDGET,
  WIGETS_FORM_REARRANGE,
  UPDATE_COMPANY_CARD,
  SET_PREVIEW_RIGHT_SIDEBAR,
  GET_ALL_WIDGET,
  USER_PROFILE_BUTTONS_ANALYTICS,

  UPDATE_WIDGET
} from "../actions";
import {
  setWidgetFormsData,
  setProfileLink,
  showProfileWidget,
  setUsernameExistError,
  setProfileTheme,
  reArrangeWidgetsFormData,
  setPreviewSideBar,
  setWidgetFormLoading,
  setWidgetFormLoadingAction,
  setPreviewWidgetLoading,
  setPreviewSideBarSuccess,
  getAllWidgetSuccess,
  saveProfileBtnAnalyticSuccess,
  saveProfileBtnAnalyticError,
} from "./actions";
import { getUserInfo } from "../auth/actions";
import { getCurrentTheme, getCurrentUser } from "helpers/Utils";
import SimpleAlert from "components/common/Swal Alert";
import instance from "redux/instance/instanceApi";
// import { createAuditLogAsync } from "helpers/Utils";
import { getUserDatafromLocal } from "helpers/Utils";

export function* watchSaveCard() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SAVE_CARD, saveCard);
}
export function* watchDeleteCard() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_CARD, deleteCardPayload);
}

export function* watchUpdateCardWidget() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_CARD_WIDGET, updateCardWidget);
}


export function* watchUpdateCard() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_CARD, updateCard);
}

export function* watchUpdateCompanyCard() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_COMPANY_CARD, updateCompanyCard);
}

export function* watchGetCard() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_CARD, getCard);
}

export function* watchGetCardByUsername() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_CARD_BY_USERNAME, getCardByUsername);
}


const saveCardAsync = async (id, card) => {
  let param = {
    id,
    jsonObject: card,
    templateId: getCurrentTheme(),
  };


  try {
    let response = await instance.post("/saveuserprofile",
    param);

    return response.data;
  } catch (error) {
    const err = error?.response?.data.message ? error?.response?.data.message : error?.response?.data
    SimpleAlert("error",err);
    // return error.response
  }
};

const updateCardAsync = async (id, card) => {
  let param = {
    id,
    // path: card.username,
    ...card,
  };

  try {

    let response = await instance.put(`/updateuserprofile/${id}`,
    param);
    return response.data;
  } catch (error) {
    console.log("error===================:",error.response);
    const err = error.response ? error.response : error.response?.data?.message ? error.response?.data?.message : error.response?.data;
    console.log("err============",err)
    SimpleAlert("error",`${err}`)
  }
};

const updateCompanyCardAsync = async (id, card) => {
  let param = {
    id,
    ...card,
  };

  try {
   
    let response = await instance.put( `/updateCompanyUsersProfile/${id}`,
    param);

    return response.data;
  } catch (error) {
    console.log("error=========updateCompanyCardAsync==========:",error.response);
    SimpleAlert("error",`${error.response.data}`)

  }
};



const getCardByUsernameAsync = async (id, card) => {
  let param = {
    id,
    card,
  };

  try {

    let response = await instance.post("/getuserprofilebyusername",
    param);

    return response.data.card;
  } catch (error) {
    const err = error.response?.data?.message ? error.response?.data?.message : error.response?.data ? error.response?.data : error.response ; 
    console.log("err err =================",err)
    console.log(" error ",error.response);
    SimpleAlert("error",`${err}`)
  }
};
function* updateCardWidget({ payload }) {
  const { id, card } = payload;
  yield put(setWidgetFormLoadingAction(true));
 

  try {
    const response = yield call(updateCardWidgetAsync, id, card);
    const actionName = "Updated card";
    const description = "Card Successfully Updated";
    
    if (response.message) {
      
      yield put(setWidgetFormLoadingAction(false));
      yield put(setWidgetFormsData(JSON.parse(card)));
    
      yield put(getUserInfo());
  
      SimpleAlert("success",`Widget update successfully!`)

    } else {
      // SimpleAlert("error",response?.data)
      yield put(setWidgetFormLoadingAction(false));
     
    }
  } catch (error) {
    yield put(setWidgetFormLoadingAction(false));
   
  }
}
const updateCardWidgetAsync = async (id, card) => {
  let param = {
    id,
    jsonObject: card,
    templateId: getCurrentTheme(),
  };
  try {
    let response = await instance.post("/saveuserprofile",
    param);
    return response.data;
  } catch (error) {
    const err = error?.response?.data.message ? error?.response?.data.message : error?.response?.data
    SimpleAlert("error",err);
  }
};

function* deleteCardPayload({ payload }) {
  const { id, card } = payload;
  yield put(setWidgetFormLoadingAction(true));
 

  try {
    const response = yield call(deleteCardAsync, id, card);
    const actionName = "Updated card";
    const description = "Card Successfully Updated";
    
    if (response.message) {

      yield put(setWidgetFormLoadingAction(false));
      yield put(setWidgetFormsData(JSON.parse(card)));
    
      yield put(getUserInfo());
  
      SimpleAlert("success",` Widget delete Successfully!`)

    } else {
      // SimpleAlert("error",response?.data)
      yield put(setWidgetFormLoadingAction(false));
     
    }
  } catch (error) {
    yield put(setWidgetFormLoadingAction(false));
   
  }
}
const deleteCardAsync = async (id, card) => {
  let param = {
    id,
    jsonObject: card,
    templateId: getCurrentTheme(),
  };


  try {
    let response = await instance.post("/saveuserprofile",
    param);

    return response.data;
  } catch (error) {
    const err = error?.response?.data.message ? error?.response?.data.message : error?.response?.data
    SimpleAlert("error",err);
    // return error.response
  }
};
function* saveCard({ payload }) {
  const { id, card } = payload;
  yield put(setWidgetFormLoadingAction(true));
 

  try {
    const response = yield call(saveCardAsync, id, card);
    // console.log("card ====saveCardAsync==========Test Zia",card);
    const actionName = "Updated card";
    const description = "Card Successfully Updated";
    
    if (response.message) {
      // yield call(
      //   createAuditLogAsync,
      //   actionName,
      //   description
      // );
      yield put(setWidgetFormLoadingAction(false));
      yield put(setWidgetFormsData(JSON.parse(card)));
    
      yield put(getUserInfo());
    
 
        SimpleAlert("success",`Profile Updated Successfully!`)

    } else {
      // SimpleAlert("error",response?.data)
      yield put(setWidgetFormLoadingAction(false));
     
    }
  } catch (error) {
    yield put(setWidgetFormLoadingAction(false));
   
  }
}

function* updateCard({ payload }) {
  const { id, card } = payload;
  // return
  try {
    const response = yield call(updateCardAsync, id, card);
    if (response.error) {
      yield put(setUsernameExistError(response));
    } else {
      yield put(setProfileLink(response.data));
      yield put(setUsernameExistError(false));
    }
  } catch (error) {
    console.log("error=========updateCard==========:",error.response);

  }
}

function* updateCompanyCard({ payload }) {
  const { id, card } = payload;
  // return
  try {
    const response = yield call(updateCompanyCardAsync, id, card);
    const actionName = "Update card";
    const description = "Card Successfully Updated";
    if (!response.error) {
     
    } else {

    }
  } catch (error) {
    console.log("error=========updateCompanyCard==========:",error.response);

  }
}

function* getCard({ payload }) {
  const { id } = payload;
  yield put(setPreviewWidgetLoading(true));

  try {
  const getUserRoleId = getUserDatafromLocal();

    const card = yield call(getCardAsync, id);

    if (card) {
      // if()
      yield put(setWidgetFormsData(JSON.parse(card.jsonObject)));
      yield put(setProfileLink(card.path));
      yield put(setProfileTheme(card.ovalThemeSelectedColor));
      yield put(showProfileWidget(false));
      yield put(setPreviewWidgetLoading(false));

    }  else {

      if(getUserRoleId?.roleId === 5 ){
        yield put(setWidgetFormsData([]));
        yield put(setProfileLink());
        yield put(setProfileTheme());
        yield put(showProfileWidget(true));
        yield put(setPreviewWidgetLoading(false));

      }else{
        yield put(setWidgetFormsData([]));
        yield put(setProfileLink());
        yield put(setProfileTheme());
        yield put(showProfileWidget(true));
      yield put(setPreviewWidgetLoading(false));

      }



    }
  } catch (error) {

    console.log("ERROR=============",error);
    yield put(showProfileWidget(true));
    yield put(setPreviewWidgetLoading(false));

  }
}
const getCardAsync = async (id, card) => {
  let param = {
    id,
    card,
  };

  try {
    let response = await instance.post("/getuserprofile", param);
    

    return response.data.card;
  } catch (error) {

    console.log("error ================",error?.response);

    
  }
};

function* getCardByUsername({ payload }) {
  const { id } = payload;
  yield put(setWidgetFormLoading(true));
  try {
    const card = yield call(getCardByUsernameAsync, id);
    yield put(setWidgetFormLoading(false));
    if (card) {
      yield put(setWidgetFormsData(JSON.parse(card.jsonObject)));
      yield put(setProfileLink(card.path));
      yield put(setProfileTheme(card.ovalThemeSelectedColor));
      yield put(showProfileWidget(false));
    } else {
      yield put(showProfileWidget(true));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchRearrangeCard() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(WIGETS_FORM_REARRANGE, rearrangeCard);
}

function* rearrangeCard({ payload }) {
  try {
    const response = yield call(rearrangeCardAsync, payload);
    if (!response.message) {
    } else {
    }
  } catch (error) {}
}

const rearrangeCardAsync = async (widgetsFormData) => {
  const userId = getCurrentUser()?.user?.id;
  let param = {
    id: userId,
    jsonObject: JSON.stringify(widgetsFormData),
    templateId: getCurrentTheme(),
  };

  try {
    let response = await instance.post("/saveuserprofile", param);

    return response.data;
  } catch (error) {
    console.log("err", error);
  }
};




export function* watchGetShowpreviewSide() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SET_PREVIEW_RIGHT_SIDEBAR, getShowPreviewSideBar);
}

function* getShowPreviewSideBar({ payload }) {
  const { show } = payload;
 
  try {
  yield put(setPreviewSideBarSuccess(show));
    
  } catch (error) {
    console.log("error tes",error.response);
    // yield put(loginUserError(error));
  }
}

export function* watchSaveBtnAnalytic() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(USER_PROFILE_BUTTONS_ANALYTICS, saveBtnAnalytic);
}

function* saveBtnAnalytic({ payload }) {
  const { btnAnalytic } = payload;
  try {
    const response = yield call(saveBtnAnalyticAsync, btnAnalytic);

    if (!response?.error) {
      yield put(saveProfileBtnAnalyticSuccess(response.message));
    } else {
      yield put(saveProfileBtnAnalyticError(response.message));
    }
  } catch (error) {}
}

const saveBtnAnalyticAsync = async (btnAnalytic) => {
  try {
    let response = await instance.post("/analytics", { ...btnAnalytic });

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export default function* rootSaga() {
  yield all([

    fork(watchSaveCard),
    fork(watchUpdateCardWidget),
    fork(watchDeleteCard),
    fork(watchUpdateCard),
    fork(watchUpdateCompanyCard),
    fork(watchGetCard),
    fork(watchGetCardByUsername),
    fork(watchRearrangeCard),
    fork(watchGetShowpreviewSide),
    fork(watchSaveBtnAnalytic),



  ]);
}
