import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
  GET_EMPLOYEES,
  GET_COMPANY_EMPLOYEE,
  SAVE_EMPLOYEE,
  SAVE_EMPLOYEE_BY_BULK,
	EMPLOYEE_BLOCK,

  GET_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  CREATE_EMPLOYEE_AUDIT_LOG,
  GET_FILTERATION_DATA_EMPLOYEE,
  RESET_FILTERATION_DATA,
  GET_FILTERATION_DATA_EMPLOYEE_BY_USERID,
  addDAloginDataInUser,
  GET_ALL_EMPLOYEES,
} from "../actions";

import {
  getEmployeesSuccess,
  getEmployeesError,
  getEmployeesByCompanySuccess,
  getEmployeesByCompanyError,
  saveEmployeeError,
  saveEmployeeSuccess,
  updateEmployeeError,
  updateEmployeeSuccess,
  getEmployeeSuccess,
  getEmployeeError,
  deleteEmployeeSuccess,
  deleteEmployeeError,
  getFilterDataEmployeeSuccess,
  getFilterDataEmployeeError,
  filterResetEmployeeSuccess,
  getFilterDataEmployeeByUserIdSuccess,
  getFilterDataEmployeeByUserIdError,
  getAllEmployeesError,
  getAllEmployeesSuccess,
  updateEmployeeBlockSuccess,
  updateEmployeeBlockError,
} from "./actions";
import SimpleAlert from "components/common/Swal Alert";
import { getIdfromToken } from "helpers/Utils";
import { employeessfilterGetSinleEmployee } from "helpers/Utils";
import instance from "redux/instance/instanceApi";
import { objectToQueryString } from "helpers/Utils";


export function* watchGetAllEmployees() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_EMPLOYEES, getAllEmployeess);
}
function* getAllEmployeess() {
  // const { history } = payload;

  try {
    const employees = yield call(getAllEmployeessAsync);
    if (!employees?.message) {
      yield put(getAllEmployeesSuccess(employees.employees  ));
    } else {
      yield put(getAllEmployeesError(employees?.message));
    }
  } catch (error) {
    console.log("error ==================",error)
    yield put(getAllEmployeesError(error));
  }
}

const getAllEmployeessAsync = async () => {
  try {
    
    
 
    let response = await instance.get(`/employees`);

    return response.data;
  } catch (error) {
    console.log("response.data eroooor",error?.response);
    return error?.response?.data


  }
};


export function* watchGetEmployees() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_EMPLOYEES, getEmployees);
}

function* getEmployees({ payload }) {
  // const { id } = payload;
  try {
    const employees = yield call(getEmployeesAsync,payload);
    const tokenId = getIdfromToken();
 

    

    if (!employees?.message) {

           
      const getfilterEmployees =    yield call(employeessfilterGetSinleEmployee,employees?.employees);
      // 
      if(tokenId?._roleId === 4){
        yield put(addDAloginDataInUser(getfilterEmployees.department));
      }

      yield put(getEmployeesSuccess( tokenId?._roleId === 4 ? getfilterEmployees?.filterEmployees:employees?.employees));
      
     
    } else {
      yield put(getEmployeesError(employees?.message));
      

    }
  } catch (error) {
    console.log("response.data eroooor getEmployeesError",error?.response);

  
  }
}

const getEmployeesAsync = async (payload) => {
  // const { id} = payload;
  try {
    // let response = await instance.get(`/employees?companyId=${id}`);
    let response = await instance.get(`/employees?`  + objectToQueryString(payload));

    return response.data;
  } catch (error) {
    console.log("response.data eroooor",error?.response);
    return error?.response?.data
  

  }
};

export function* watchGetEmployeesByCompany() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_COMPANY_EMPLOYEE, getEmployeesByCompany);
}

function* getEmployeesByCompany() {
  // const { history } = payload;

  try {
    const employees = yield call(getEmployeesByCompanyAsync);

    if (!employees?.message) {
      // yield put(getEmployeesByCompanySuccess(employees.departments));
    } else {
      yield put(getEmployeesByCompanyError(departments?.message));
    }
  } catch (error) {
    yield put(getEmployeesByCompanyError(error));
  }
}

const getEmployeesByCompanyAsync = async () => {
  try {
   
    let response = await instance.get(`/employee/company`);

    return response.data;
  } catch (error) {
    console.log("response.data eroooor",error?.response);

  }
};

export function* watchSaveEmployee() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SAVE_EMPLOYEE, saveEmployee);
}


function* saveEmployee({ payload }) {
  const { employee,companyId } = payload;
  try {
    const actionName = "Add  Employee";
    const description = "Add a new employee :";

    const response = yield call(saveEmployeeAsync, employee);
    if (!response?.error) {
      // const responseAudit = yield call(
      //   createAuditLogAsync,
      //   response?.employee,
      //   actionName,
      //   description,companyId
      // );
      yield put(saveEmployeeSuccess(response?.message));

        
    } else {
      console.log("response====saveEmployee====",response);
      yield put(saveEmployeeError(response?.message));
    }
  } catch (error) {
    console.log("error error===saveEmployee===",error)
  }
}

const saveEmployeeAsync = async (employee) => {
  try {
  
    let response = await instance.post("/employee",
    { ...employee });
    return response.data;
  } catch (error) {

    console.log("error saveEmployeeAsync response", error.response);
    return error.response?.data
  }
};
export function* watchSaveEmployeeByBulk() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SAVE_EMPLOYEE_BY_BULK, saveEmployeebybulk);
}

function* saveEmployeebybulk({ payload }) {
  const { data,companyId } = payload;
  try {
    const actionName = "Add  Employees";
    const description = "Add a new employees :";

    const response = yield call(saveEmployeeByBulkAsync, data,companyId);
    if (!response?.error) {
      // const responseAudit = yield call(
      //   createAuditLogAsync,
      //   response?.employee,
      //   actionName,
      //   description,companyId
      // );
      yield put(saveEmployeeSuccess(response?.message));

        
    } else {
      yield put(saveEmployeeError(response?.message));
    }
  } catch (error) {
    console.log("error error======",error)
  }
}
const saveEmployeeByBulkAsync = async (data,companyId) => {
  try {
  
    let response = await instance.post("/employee/bulk",
    { data,companyId });
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};
export function* watchGetEmployee() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_EMPLOYEE, getEmployee);
}

function* getEmployee({ payload }) {
  const { id } = payload;
  try {
    const employee = yield call(getEmployeeAsync, id);

    if (!employee?.message) {
      yield put(getEmployeeSuccess(employee));
    } else {
      yield put(getEmployeeError(employee?.message));
    }
  } catch (error) {
    yield put(getEmployeeError(error));
  }
}

const getEmployeeAsync = async (id) => {
  try {
   
    let response = await instance.get("/employee/" + id);

    return response.data;
  } catch (error) {}
};

export function* watchEmployeeBlock() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(EMPLOYEE_BLOCK, employeeBlock);
}
function* employeeBlock({ payload }) {
  const { employee ,companyId} = payload;
  try {
    const response = yield call(employeeBlockAsync, employee);

    if (!response?.error) {
      yield put(updateEmployeeBlockSuccess(response?.message));
      SimpleAlert("success","Employee Updated successfully");
    } else {
      yield put(updateEmployeeBlockError(response?.message));
    }
  } catch (error) {
    yield put(updateEmployeeBlockError(error))
  }
}

const employeeBlockAsync = async (employee) => {
  try {
    
    let response = await instance.post("/employeeblock/" + employee.id,
    { ...employee });

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export function* watchUpdateEmployee() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_EMPLOYEE, updateEmployee);
}

function* updateEmployee({ payload }) {
  const { employee ,companyId} = payload;
  const actionName = "Update Employee";
  try {
    const response = yield call(updateEmployeeAsync, employee);
    const description = "Update employee :";
    console.log("response?=======functionnnnnnnnnnn===========",response);

    if (!response?.error) {
      // const responseAudit = yield call(createAuditLogAsync, employee.id,actionName,description,companyId);
      yield put(updateEmployeeSuccess(response?.message));
      console.log("response?.error============iffffffffffff======",response?.error);
      
    } else {
      yield put(updateEmployeeError(response?.message));
      console.log("response?.error=======elsssssssssss===========",response?.error);
      
    }
  } catch (error) {}
}

const updateEmployeeAsync = async (employee) => {
  try {
    
    let response = await instance.post("/employee/" + employee.id,
    { ...employee });

    return response.data;
  } catch (error) {
    console.log("error", error);
    const err = error?.response.data?.message ? error?.response.data?.message : error?.response.data ? error?.response.data : ""
        SimpleAlert("error",`${err}`)
  }
};

export function* watchDeleteEmployee() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_EMPLOYEE, deleteEmployee);
}

function* deleteEmployee({ payload }) {
  const { id,companyId } = payload;
  try {
    const actionName = "delete  Employee";
    const employee = yield call(deleteEmployeeAsync, id);

   
    const employ = {
      id: id,
    };
    const description = "Delete employee :";
    if (employee?.message) {
      // const responseAudit = yield call(createAuditLogAsync, employ, actionName,description,companyId);

      yield put(deleteEmployeeSuccess(id));
      SimpleAlert("success","Employee deleted successfully");

    } else {

      yield put(deleteEmployeeError(employee?.message));
    }
  } catch (error) {
    yield put(deleteEmployeeError(error));
  }
}

const deleteEmployeeAsync = async (id) => {
  try {
   
    let response = await instance.delete( "/employee/" + id);
    return response.data;
  } catch (error) {}
};



export function* watchGetFilterDataEmployee() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_FILTERATION_DATA_EMPLOYEE, getfilterDataEmployees);
}

function* getfilterDataEmployees({ payload }) {

  try {
    const employees = yield call(getFilterDataEmployeesAsync, payload);

    if (!employees?.message) {
      yield put(getFilterDataEmployeeSuccess(employees?.employees));
    } else {
      yield put(getFilterDataEmployeeError(employees?.message));
    }
  } catch (error) {
    yield put(getFilterDataEmployeeError(error));
  }
}

const getFilterDataEmployeesAsync = async (payload) => {
  try {
    // const data = {
    //   teamId:payload?.filterName?.teamId_Emp,
    //   departmentId:payload?.filterName?.departmentId_Emp,
    //   designation:payload?.filterName?.search_role,
    //   name:payload?.filterName?.search_name,
    //   companyId:payload?.filterName?.companyId

    // }
    console.log("payload==================",payload);

  
   
    let response = await instance.get(`/employees?${objectToQueryString(payload)}`);
    return response.data;
  } catch (error) {}
};


export function* watchGetFilterDataEmployeeByUserId() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_FILTERATION_DATA_EMPLOYEE_BY_USERID, getfilterDataEmployeesByUserId);
}

function* getfilterDataEmployeesByUserId({ payload }) {

  try {
    const employees = yield call(getFilterDataEmployeesByUserIdAsync, payload);

    if (!employees?.message) {
      const data = employees?.employees[0];
      yield put(getFilterDataEmployeeByUserIdSuccess(data));
    } else {
      yield put(getFilterDataEmployeeByUserIdError(employees?.message));
    }
  } catch (error) {
    yield put(getFilterDataEmployeeByUserIdError(error));
  }
}
const getFilterDataEmployeesByUserIdAsync = async (payload) => {
  try {
    const {userId} = payload;
    // const userId = payload?.filterName?.teamId_Emp;
   
   
    let response = await instance.get(`/employees?userId=${userId}`);
    return response.data;
  } catch (error) {
    console.log("error========",error.response)
  }
};

export function* watchResetFilters() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_FILTERATION_DATA, getResetFiltersFunct);
}

function* getResetFiltersFunct({ payload }) {
  try {
    yield put(filterResetEmployeeSuccess(payload?.employees));
  } catch (error) {
    // yield put(getFilternationDataError(error));
    SimpleAlert("error", error);
  }
}


export default function* rootSaga() {
  yield all([
    fork(watchGetEmployees),
    fork(watchEmployeeBlock),
    fork(watchSaveEmployeeByBulk),
    fork(watchSaveEmployee),
    fork(watchUpdateEmployee),
    fork(watchGetEmployee),
    fork(watchDeleteEmployee),
    fork(watchGetEmployeesByCompany),
    fork(watchGetFilterDataEmployee),
    fork(watchResetFilters),
    fork(watchGetFilterDataEmployeeByUserId),
    fork(watchGetAllEmployees),



  ]);
}
