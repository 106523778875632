import {
	GET_EXCHANGECONTACT,
	GET_EXCHANGECONTACT_SUCCESS,
	GET_EXCHANGECONTACT_ERROR,

	GET_EXCHANGECONTACT_BY_COMPANY,
	GET_EXCHANGECONTACT_BY_COMPANY_SUCCESS,
	GET_EXCHANGECONTACT_BY_COMPANY_ERROR,

	GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME,
	GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_SUCCESS,
	GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_ERROR,
	SAVE_EXCHANGECONTACT,
	SAVE_EXCHANGECONTACT_SUCCESS,
	SAVE_EXCHANGECONTACT_ERROR,

	UPDATE_EXCHANGECONTACT,
	UPDATE_EXCHANGECONTACT_SUCCESS,
	UPDATE_EXCHANGECONTACT_ERROR,

	DELETE_EXCHANGECONTACT,
	DELETE_EXCHANGECONTACT_SUCCESS,
	DELETE_EXCHANGECONTACT_ERROR,


	GET_EXCHANGECONTACT_FILTERATION_DATA,
	GET_EXCHANGECONTACT_FILTERATION_DATA_SUCCESS,
	GET_EXCHANGECONTACT_FILTERATION_DATA_ERROR,

	CLEAR_EXCHANGE_CONTACT_SUCCESS,

} from "../actions";

export const saveExchangeContact = (exchangeContact) => ({
	type: SAVE_EXCHANGECONTACT,
	payload:  exchangeContact ,
});

export const saveExchangeContactSuccess = (message) => ({
	type: SAVE_EXCHANGECONTACT_SUCCESS,
	payload: {message},
});

export const saveExchangeContactError = (message) => ({
	type: SAVE_EXCHANGECONTACT_ERROR,
	payload: { message },
});

export const getExchangeContact = (id) => ({
	type: GET_EXCHANGECONTACT,
	payload: { id },
});

export const getExchangeContactSuccess = (deparment) => ({
	type: GET_EXCHANGECONTACT_SUCCESS,
	payload: deparment,
});

export const getExchangeContactError = (message) => ({
	type: GET_EXCHANGECONTACT_ERROR,
	payload: { message },
});

export const getExchangeContactByCompany = (data) => ({
	type: GET_EXCHANGECONTACT_BY_COMPANY,
	payload: data ,
});
export const getExchangeContactByCompanySuccess = (exchangeContacts) => ({
	type: GET_EXCHANGECONTACT_BY_COMPANY_SUCCESS,
	payload: exchangeContacts,
});

export const getExchangeContactByCompanymentError = (message) => ({
	type: GET_EXCHANGECONTACT_BY_COMPANY_ERROR,
	payload: { message },
});




export const getExchangeContactByEmployeeName = (id) => ({
	type: GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME,
	payload: { id },
});
export const getExchangeContactByEmployeeNameSuccess = (exchangeContacts) => ({
	type: GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_SUCCESS,
	payload: exchangeContacts,
});

export const getExchangeContactByEmployeeNameError = (message) => ({
	type: GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_ERROR,
	payload: { message },
});




export const updateExchangeContact = (exchangeContact,dep_id) => ({
	type: UPDATE_EXCHANGECONTACT,
	payload: { exchangeContact,dep_id },
});

export const updateExchangeContactSuccess = (message) => ({
	type: UPDATE_EXCHANGECONTACT_SUCCESS,
	payload: {message},
});

export const updateExchangeContactError = (message) => ({
	type: UPDATE_EXCHANGECONTACT_ERROR,
	payload: { message },
});

export const deleteExchangeContact = (id) => ({
	type: DELETE_EXCHANGECONTACT,
	payload: { id },
});

export const deleteExchangeContactSuccess = (id) => {
    return {
        type: DELETE_EXCHANGECONTACT_SUCCESS,
        payload: {id},
    };
};
export const deleteExchangeContactError = (message) => ({
	type: DELETE_EXCHANGECONTACT_ERROR,
	payload: { message },
});

export const getFilterationData = (search) => {
	return{
		type: GET_EXCHANGECONTACT_FILTERATION_DATA,
		payload:  search ,
	}
	
};

export const getFilterationDataSuccess = (exchangeContacts) => {
	return {
		type: GET_EXCHANGECONTACT_FILTERATION_DATA_SUCCESS,
		payload:  exchangeContacts,
    };
};

export const getFilterationDataError = (message) => {
	return {
		type: GET_EXCHANGECONTACT_FILTERATION_DATA_ERROR,
		payload:  {message},
    };
};

export const clearExchangeContactSuccess = () => {
	return {
	  type: CLEAR_EXCHANGE_CONTACT_SUCCESS,
	};
  }

