import {
  GET_TEAMS,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_ERROR,
  GET_TEAM_BY_ID,
  GET_TEAM_BY_ID_SUCCESS,
  GET_TEAM_BY_ID_ERROR,
	GET_ALL_TEAMS,GET_ALL_TEAMS_SUCCESS,GET_ALL_TEAMS_ERROR,


  GET_FILTERATION_DATA_TEAM,
  GET_FILTERATION_DATA_TEAM_SUCCESS,
  GET_FILTERATION_DATA_TEAM_ERROR,

  GET_TEAMS_BY_COMPANY,
  GET_TEAMS_BY_COMPANY_SUCCESS,
  GET_TEAMS_BY_COMPANY_ERROR,

  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  SAVE_TEAM,
  SAVE_TEAM_SUCCESS,
  SAVE_TEAM_ERROR,
  UPDATE_TEAM_DATA,
  UPDATE_TEAM_DATA_SUCCESS,
  UPDATE_TEAM_DATA_ERROR,
  CLEAR_TEAM_SUCCESS,
  
  RESET_FILTERATION_DATA,
  RESET_FILTERATION_DATA_SUCCESS,
  LOGOUT_USER,
} from "../actions";

const INIT_STATE = {
  teams: [],
  filterData: [],
  secondLoading:false,
  teamsLoader: false,
  loadingData:{},
  loading: false,
  error: "",
  success: "",
  modalSuccess: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL_TEAMS:
      return { ...state, teamsLoader: true, error: "" };
    case GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        error: "",
        teamsLoader: false,
      };
    case GET_ALL_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        teams: [],
        error: action.payload.message,
        teamsLoader: false,
      };

      case GET_TEAMS_BY_COMPANY:
        return { ...state, teamsLoader: true, error: "" };
      case GET_TEAMS_BY_COMPANY_SUCCESS:
        return {
          ...state,
          teams: action.payload,
          error: "",
          teamsLoader: false,
        };
      case GET_TEAMS_BY_COMPANY_ERROR:
        return {
          ...state,
          loading: false,
          teams: [],
          error: action.payload.message,
          teamsLoader: false,
        };

    case GET_TEAMS:
      return { ...state, loading: true,teamsLoader: true, error: "" };
    case GET_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload,
        error: "",
        success: false,
        teamsLoader: false,
      };
    case GET_TEAMS_ERROR:
      return {
        ...state,
        loading: false,
        teams: {},
        error: action.payload.message,
        teamsLoader: false,
      };

    case GET_TEAM_BY_ID:
      return { ...state, loading: true,teamsLoader: true, error: "" };
    case GET_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: action.payload,
        error: "",
        teamsLoader: false,
      };
    case GET_TEAM_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        teams: {},
        error: action.payload.message,
        teamsLoader: false,
      };

    case SAVE_TEAM:
      return { ...state, loading: true, error: "", modalSuccess: "" };
    case SAVE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        teams: [...state.teams, action.payload],
        error: "",
        success: true,
      };
    case SAVE_TEAM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        modalSuccess: "",
      };

    case UPDATE_TEAM_DATA:
      return { ...state, loading: true, error: "", success: "" };
    case UPDATE_TEAM_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };
    case UPDATE_TEAM_DATA_ERROR:
      return {
        ...state,
        loading: false,
        teams: {},
        error: action.payload.message,
        success: "",
      };
    case GET_FILTERATION_DATA_TEAM:
      return { ...state, loading: true, teamsLoader: true,error: "" };
    case GET_FILTERATION_DATA_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        filterData: action.payload,
        success: "",
        teamsLoader: false,
      };
    case GET_FILTERATION_DATA_TEAM_ERROR:
        
        return { ...state, loading: true,teamsLoader: false, error: "" };
    case CLEAR_TEAM_SUCCESS:
      return { ...state, loading: false, error: "", success: "" };
    case RESET_FILTERATION_DATA:
      return { ...state, loading: true, error: "" };
    case RESET_FILTERATION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        filterData: action.payload,
        error: "",

      };
    case DELETE_TEAM:
      return { ...state, secondLoading: true,loadingData:action.payload, error: "" };

    case DELETE_TEAM_SUCCESS:

      return {
        ...state,
        loading: false,
        teams: state.teams?.filter((el) => {
          return el?.id !== action?.payload?.id;
        }),
        error: "",
        loadingData:{},
        secondLoading: false,
      };
  

    case DELETE_TEAM_ERROR:
      return {
        ...state,
        loading: false,
        // employee: {},
        error: action.payload.message,
        loadingData:{},
        secondLoading: false,
      };

    default:
      return { ...state };
  }
};
